import { useState, forwardRef, useMemo } from "react";
import { NumberFormatParse } from "./NumberFormatParser";

export const LocaleNumberInput = forwardRef(
  ({ locale, defaultValue, onChange, ...inputProps }, ref) => {
    const [numberFormatter, numberParser] = useMemo(
      () => [new Intl.NumberFormat(locale), new NumberFormatParse(locale)],
      [locale]
    );

    const [inputValue, setInputValue] = useState(
      defaultValue ? numberFormatter.format(defaultValue) : defaultValue
    );

    const handleValueChange = (e) => {
      const { value } = e.target;

      // eslint-disable-next-line no-useless-escape
      if (/[1234567890\.,\,]$/.test(value)) {
        const numberValue = numberParser.parse(value);

        if (!Number.isNaN(numberValue)) {
          onChange(numberValue);
        } else {
          onChange("");
        }
        const existingNumber = numberParser.parse(inputValue);

        if (numberValue === existingNumber) {
          // must be a . or , at the end so don't remove it
          setInputValue(value);
        } else {
          setInputValue(numberFormatter.format(numberValue));
        }
      } else if (!value) {
        onChange("");
        setInputValue(value);
      }
    };

    return (
      <input
        ref={ref}
        {...inputProps}
        type="text"
        value={inputValue}
        onChange={handleValueChange}
      />
    );
  }
);
