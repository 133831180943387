import React, { useCallback, useState, useMemo } from "react";
import { ReactComponent as KeyboardSvg } from "../svgs/keyboard.svg";
import { useLocale } from "../useLocale";
import { LocaleNumberInput } from "./LocaleNumberInput";
import { RejectButton } from "./RejectButton";
import { GotoAllStepsButton } from "./GotoAllStepsButton";

export const WeightCapture = ({
  title,
  instruction,
  value,
  onChange,
  dispatch,
}) => {
  const [error, setError] = useState("");
  const remoteScalesSupport = false;
  const [manualInput, setManualInput] = useState(!remoteScalesSupport);

  const locale = useLocale();
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(locale, { style: "decimal" }),
    [locale]
  );

  const trackingInputCallback = useCallback(
    (inputElement) => {
      if (inputElement || manualInput) {
        inputElement?.focus();
      }
    },
    [manualInput]
  );

  return (
    <div className="flex gap-8 flex-col p-8">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between">
          <h1 className="text-6xl text-[#282F44]">{title}</h1>
          <div className="">
            {remoteScalesSupport && (
              <button
                className="p-6 bg-white rounded-lg"
                onClick={() => {
                  setManualInput(!manualInput);
                }}
              >
                <KeyboardSvg style={{ width: "24px", height: "24px" }} />
              </button>
            )}
            <GotoAllStepsButton dispatch={dispatch} />
          </div>
        </div>
        <p className="text-4xl font-medium text-[#464C64] max-w-[80%]">
          {instruction}
        </p>
      </div>
      <div
        className="grid grid-cols-2 w-full gap-8"
        style={
          !manualInput
            ? { height: "0", opacity: "0", position: "fixed", top: -1000 }
            : {}
        }
      >
        <div className="" />
        <div className="flex flex-row justify-center items-center w-full">
          <LocaleNumberInput
            ref={trackingInputCallback}
            className="rounded-l-lg p-4 border-2 border-[#C6CCE8] focus:outline-none focus:ring-0 text-5xl font-normal w-full"
            defaultValue={value}
            locale={locale}
            onChange={(val) => onChange(val)}
          />
          <div className="w-[6rem] h-full flex items-center justify-center rounded-r-lg border-2 border-l-0 border-[#C6CCE8]">
            Kg
          </div>
        </div>
      </div>
      {error && (
        <div
          className="text-white text-xl h-4 flex flex-row gap-2 align-top mb-large"
          style={{
            color: "red",
          }}
        >
          {error}
        </div>
      )}
      <div className="grid grid-cols-2 w-full gap-8">
        <RejectButton
          onError={(message) => setError(message)}
          dispatch={dispatch}
        />
        <button
          className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
          onClick={() => {
            dispatch({ type: "next" });
          }}
        >
          Record Weight
          <span style={{ display: manualInput ? "none" : "inline" }}>
            {" "}
            - {numberFormatter.format(value)} kg
          </span>
        </button>
      </div>
    </div>
  );
};
