import { Header } from "./Header";

import { ReactComponent as BinSVG } from "../svgs/bin.svg";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";
import React from "react";

export const ViewBucketPhoto = ({
  dispatch,
  title,
  photo,
  onDelete,
  onClose,
}) => {
  return (
    <div className="fixed inset-0 h-full w-full">
      <div className="relative bg-white h-full min-h-[960px]">
        <div className="fixed h-full w-full">
          <img className="h-full w-full" src={photo} alt={title} />
        </div>
        <Header dispatch={dispatch} opacity="100%" noExit>
          <div className="absolute left top-0 flex items-center h-full">
            <button onClick={() => onClose()} type="button" className="">
              <CloseSvg className="w-6 h-6 fill-[#282F44]" alt="Close" />
            </button>
          </div>
          <div className="flex justify-center items-center">
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="absolute right-8 top-0 flex items-center h-full">
            <button onClick={() => onDelete()} type="button">
              <BinSVG
                style={{ width: "1.5rem" }}
                className="w-12 h-12 fill-[#282F44]"
                alt="Delete"
              />
            </button>
          </div>
        </Header>
      </div>
    </div>
  );
};
