import React, { useRef } from "react";
import Webcam from "react-webcam";

import { useWindowSize } from "../useWindowSize";
import { TakePhotoButton } from "./TakePhotoButton";

export const TakePhoto = ({ dispatch, title, onCancel, onPhotoTaken }) => {
  const { width, height, aspectRatio } = useWindowSize();

  const webcamRef = useRef(null);

  const handleCapturePackage = () => {
    const picture = webcamRef.current.getScreenshot();

    onPhotoTaken(picture);
  };

  return (
    <div className="z-50 fixed inset-0 h-full w-full">
      <div className="relative bg-white h-full">
        <div className="fixed">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={`${width}`}
            height={`${height}`}
            screenshotQuality={1}
            forceScreenshotSourceSize={true}
            videoConstraints={{
              width: { ideal: width },
              height: { ideal: height },
              aspectRatio: { ideal: aspectRatio },
              facingMode: { ideal: "environment" },
            }}
          />
        </div>

        <div className="absolute bottom-0 left-0 bg-white w-full text-4xl font-normal">
          <div className="flex gap-8 flex-col p-8">
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                {title}
              </h1>
              <p className="text-4xl font-medium text-[#464C64]">
                Spread the contents out on the sorting surface and take a top
                down photo.
              </p>
            </div>

            <div className="flex flex-row w-full gap-8">
              <button
                className="rounded-lg text-red border-2 border-red py-4 w-full m-0"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
              <TakePhotoButton
                dispatch={dispatch}
                onPhotoCapture={handleCapturePackage}
                webcam={webcamRef.current}
                title={title}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
