import { useEffect, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useOutletContext } from "react-router-dom";
import { Input } from "components/input";
import "material-symbols/outlined.css";
import { getQueryParams, isValidEmail } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useUserExists } from "api/use-user-exists";
import { EmailValidation } from "components/email-validation";
import InputValidation from "components/input-validation";
import { isLegoEmail } from "consts/lego-admin";
import { useTranslation } from "react-i18next";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";

export default function EmailPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  console.log({ email });

  // Reset right side visual
  const { setSidebarVisual, setEmailInput } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("man");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  // Check for valid email
  const [emailAlarm, setEmailAlarm] = useState(false);
  const [legoError, setLegoError] = useState(null);
  const { exists, isLoading } = useUserExists({
    email,
    skip: !isValidEmail(email),
  });
  const destination = exists
    ? `${t("urls.trade_in_sign_in")}`
    : `${t("urls.trade_in_sign_up")}`;

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
    setEmailInput(newValue);
    setEmailAlarm(!isValidEmail(newValue));
    if (isLegoEmail(newValue)) {
      setLegoError(t("login.lego_alarm"));
    } else {
      setLegoError(null);
    }
  };

  const handleClick = (e) => {
    const emailError = !isValidEmail(email);

    if (emailError) {
      e.preventDefault();
      setEmailAlarm(true);
    } else {
      setEmailAlarm(false);
      setLegoError(null);
    }
  };

  const updatedParams = { ...queryParams, email };

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={destination}
      submitParams={updatedParams}
      submitDisabled={!email || isLoading || emailAlarm || legoError}
      onSubmit={handleClick}
      secondaryLabel={t("ui.button_back")}
      secondaryParams={updatedParams}
      secondaryHref={`${t("urls.trade_in_payout_method")}`}
    >
      <ProgressBar step={5} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("trade-in.email.title")}
      </h2>
      <div action="" className="w-full flex flex-col">
        <div>
          <Input
            label={t("trade-in.email.label")}
            type="email"
            onChange={handleInputChange}
            value={email}
          />
          <EmailValidation emailAlarm={emailAlarm} />
          <InputValidation className="mt-2" errorMessage={legoError} />
          <div className="mt-8 text-left flex gap-1 items-start">
            <span
              className={`material-symbols-outlined scale-75 text-blue align-bottom block`}
            >
              info
            </span>{" "}
            <span className="text-sm text-gray-500">
              {t("login.sign_up_warning")}
            </span>
          </div>
        </div>
      </div>
    </FormContainer>
  );
}
