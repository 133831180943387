import React, { useContext, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { Input } from "components/input";
import { getQueryParams } from "lib/utils";
import { isValidEmail } from "lib/utils";
import { isLegoEmail } from "consts/lego-admin";
import AuthContext from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import ForgotPasswordLink from "components/forgot-password-link";
import InputValidation from "components/input-validation";
import PasswordInput from "components/password-input";
import "material-symbols/outlined.css";

export default function InStoreSignin() {
  const { t } = useTranslation();
  const { loginHandler, loginError } = useContext(AuthContext);
  if (loginError) {
    console.error("Login error", loginError);
  }
  const [email, setEmail] = useState(localStorage.getItem("emailInput") || "");
  const [password, setPassword] = useState("");
  const [emailAlarm, setEmailAlarm] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [legoError, setLegoError] = useState(null);
  const navigate = useNavigate();

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  //useRestartIfAbsent(queryParams, ["e", "l"], "/signup-store/start");

  // Handle input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailAlarm(!isValidEmail(value));
    if (isLegoEmail(value)) {
      setLegoError(t("login.lego_alarm"));
    } else {
      setLegoError(null);
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    setEmailAlarm(!isValidEmail(email));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSubmit = () => {
    loginHandler(
      { username: email, password },
      {
        onSuccess: () => {
          const navigateOptions = {
            pathname: t("urls.signup_store_confirmation"),
          };
          if (queryParams) {
            navigateOptions.search = createSearchParams(queryParams).toString();
          }
          navigate(navigateOptions);
          navigate(0);
        },
      }
    );
  };

  const updatedParams = { ...queryParams, e: email };

  const allFieldsValid = email && password && !emailAlarm && !legoError;

  return (
    <FormContainer
      submitLabel="Continue"
      submitParams={updatedParams}
      onSubmit={handleSubmit}
      submitDisabled={!allFieldsValid}
      secondaryLabel="Back"
      secondaryHref={t("urls.signup_store_email")}
      secondaryParams={queryParams}
    >
      <ProgressBar step={2} stepsNum={3} />
      <h2 className="font-semibold text-6xl max-w-lg">
        Welcome back! Sign in to continue
      </h2>
      <div className="w-full flex flex-col gap-x-large">
        <div>
          <Input
            name="email"
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            value={email}
            label="Email Address"
            type="email"
          />
          {legoError && (
            <InputValidation className="mt-2" errorMessage={legoError} />
          )}
          {emailAlarm && emailTouched && (
            <div className="text-[#757575] font-light mt-2">
              <span className="material-symbols-outlined scale-75 text-[#e3000b] align-bottom mr-1">
                error
              </span>
              Please enter a valid email.
            </div>
          )}
        </div>
        <div>
          <PasswordInput
            name="password"
            label="Password"
            type="password"
            onChange={handlePasswordChange}
            value={password}
          />
          <div className="text-blue mt-regular text-sm cursor-pointer">
            <ForgotPasswordLink returnToPath={t("urls.signup_store_sign_in")} />
          </div>
          {loginError && <InputValidation errorMessage={loginError.message} />}
        </div>
      </div>
    </FormContainer>
  );
}
