import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";
import { useWarehouseContext } from "../useWarehouseContext";
import ExitModal from "./ExitModal";

export const Header = ({
  dispatch,
  showModal = true,
  opacity = "100%",
  noExit = false,
  children,
}) => {
  const { clearTakeback } = useWarehouseContext();

  const [showExitModal, setShowExitModal] = useState(false);

  const onExit = () => {
    clearTakeback();
    dispatch({ type: "exit" });
  };

  return (
    <div
      className="flex p-6 bg-white w-full min-h-[6rem] justify-between items-center"
      style={{ opacity }}
    >
      {!noExit && (
        <button
          onClick={() =>
            showModal === true ? setShowExitModal(true) : onExit()
          }
          type="button"
          className="rounded-lg text-white bg-transparent p-1.5 inline-flex items-center"
        >
          <CloseSvg className="w-6 h-6 fill-[#282F44]" alt="Close" />
        </button>
      )}
      {showExitModal && (
        <AnimatePresence>
          <ExitModal
            isOpen={showExitModal}
            onClose={() => setShowExitModal(false)}
            onConfirm={onExit}
            title="Quit Processing Take Back"
            body="Are you sure you want to quit? All current processing for this take back will be lost."
            primaryText="Confirm"
            secondaryText="Back"
          />
        </AnimatePresence>
      )}
      <div className="w-full">{children}</div>
    </div>
  );
};
