import React, { useMemo } from "react";

import { useWarehouseContext } from "./useWarehouseContext";

import { CapturePackagePhotos } from "./CapturePackagePhotos";
import { StepHeader } from "./components/StepHeader";
import { useLocale } from "./useLocale";
import { CheckContents } from "./CheckContents";
import { clsx } from "clsx";
import { ControlWeightCapture } from "./ControlWeightCapture";
import { ContentWeightCapture } from "./ContentWeightCapture";
import { CaptureContentPhotos } from "./CaptureContentPhotos";
import { SortContents } from "./SortContents";
import { AssembledWeightCapture } from "./AssembledWeightCapture";
import { ConformingWeightCapture } from "./ConformingWeightCapture";
import { Summary } from "./Summary";

import { ReactComponent as CloseSvg } from "./svgs/close.svg";
import { ReactComponent as WarningSvg } from "./svgs/warning.svg";
import { FullscreenModal } from "./components/FullscreenModal";

const WeightDisplay = ({ weight }) => {
  const locale = useLocale();
  const formatter = useMemo(
    () => new Intl.NumberFormat(locale, { style: "decimal" }),
    [locale]
  );

  const formatWeight = (weight) => {
    return weight !== "" ? formatter.format(weight) : "-";
  };

  return weight ? (
    <span className="font-bold">
      {formatWeight(weight)}
      <span className="text-xl ml-2">kg</span>
    </span>
  ) : (
    <div className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center">
      <WarningSvg style={{ width: "1rem" }} /> Weight not recorded
    </div>
  );
};

const PhotosDisplay = ({ photos }) => {
  return photos.length > 0 ? (
    <div className="grid gap-2 grid-cols-6 lg:grid-cols-12">
      {photos.map((image, index) => (
        <div key={index} className="relative">
          <img
            src={image}
            alt={`Captured ${index}`}
            className="w-full h-[2.5rem] border rounded-lg"
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center w-full">
      <WarningSvg style={{ width: "1rem" }} /> Photos not taken
    </div>
  );
};

export const AllSteps = ({ dispatch }) => {
  const {
    packagePictures,
    controlWeight,
    contentWeight,
    contentPictures,
    sortedPictures,
    assembledWeight,
    conformingWeight,
  } = useWarehouseContext();
  const jumpTo = (StepComponent) => {
    dispatch({
      type: "jump-to",
      props: { StepComponent },
    });
  };

  const stepsStatus = [
    {
      step: CapturePackagePhotos,
      valid: packagePictures.length > 0,
      value: <PhotosDisplay photos={packagePictures} />,
    },
    {
      step: CheckContents,
      valid: true,
      value: <span className="font-bold">Accepted</span>,
    },
    {
      step: ControlWeightCapture,
      valid: controlWeight !== "",
      value: <WeightDisplay weight={controlWeight} />,
    },
    {
      step: ContentWeightCapture,
      valid: contentWeight !== "",
      value: <WeightDisplay weight={contentWeight} />,
    },
    {
      step: CaptureContentPhotos,
      valid: contentPictures.length > 0,
      value: <PhotosDisplay photos={contentPictures} />,
    },
    {
      step: SortContents,
      valid: Object.values(sortedPictures).length > 0,
      value: <PhotosDisplay photos={Object.values(sortedPictures)} />,
    },
    {
      step: AssembledWeightCapture,
      valid: assembledWeight !== "",
      value: <WeightDisplay weight={assembledWeight} />,
    },
    {
      step: ConformingWeightCapture,
      valid: conformingWeight !== "",
      value: <WeightDisplay weight={conformingWeight} />,
    },
    { step: Summary, valid: true },
  ];

  return (
    <FullscreenModal>
      <StepHeader
        dispatch={dispatch}
        noExit
        rightSlot={
          <button
            onClick={() => dispatch({ type: "close-all-steps" })}
            type="button"
            className="rounded-lg text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 p-1.5 inline-flex items-center"
          >
            <CloseSvg className="w-6 h-6 fill-[#282F44]" alt="Close" />
          </button>
        }
      />
      <div className="absolute w-full flex flex-col">
        <ul className="h-full w-full p-0 flex flex-col mt-0.5">
          {stepsStatus.map((row, index) => {
            return (
              <div
                key={`step-${index + 1}`}
                onClick={() => {
                  jumpTo(row.step);
                }}
              >
                <li
                  className={clsx(
                    "relative flex flex-row items-center gap-8 m-0 p-4 border-b-2",
                    {
                      "bg-[#EFF6FF] border-b-[#DAE3F9]":
                        row.valid && index % 2 === 0,
                      "bg-white border-b-[#DAE3F9]":
                        row.valid && index % 2 !== 0,
                      "bg-[#F9F0DA] border-b-[#F0D9A2]": !row.valid,
                    }
                  )}
                >
                  {index > 0 && (
                    <div className="absolute top-0 left-[2.45rem] w-1 h-1/2 border-l-2 border-l-[#DAE3F9]" />
                  )}
                  {index + 1 < stepsStatus.length && (
                    <div className="absolute bottom-0 left-[2.45rem] w-1 h-1/2 border-l-2  border-l-[#DAE3F9]" />
                  )}
                  <div
                    className={clsx("z-10 rounded-full w-[1rem] h-[1rem] m-4", {
                      "bg-[#D8A924]": !row.valid,
                      "bg-[#00CFA8]":
                        row.valid && index + 1 !== stepsStatus.length,
                      "bg-[#173991]":
                        row.valid && index + 1 === stepsStatus.length,
                    })}
                  />
                  <div className="flex flex-col">
                    <p>{row.step.title}</p>
                    <div>{row.value}</div>
                  </div>
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </FullscreenModal>
  );
};
