import React, {
  useContext,
  Fragment,
  useEffect,
  useState,
  useRef,
} from "react";
import { useLocation, useOutletContext, Link } from "react-router-dom";
import "material-symbols/outlined.css";
import { Button } from "components/button";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation, Trans } from "react-i18next";
import {
  TAKEBACK_METHOD_NAME,
  TAKEBACK_ID,
  COUNTRY_NAME,
} from "consts/query-params";
import { useTakebackById } from "../../api/use-takebacks";
import AuthContext from "contexts/AuthContext";
import InputValidation from "../../components/input-validation";
import storeLocations from "data/stores.json";

const { REACT_APP_API } = process.env;

const fetchTakeback = async ({ accessToken, id, setPdfUrl }) => {
  try {
    const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch takeback");
    }

    const data = await response.json();
    const labelBase64 =
      data?.[0]?.u_lego_dhl_label_b64_encoded ||
      data?.[0]?.u_ups_shipping_label_encoded;

    if (labelBase64) {
      const base64Data = labelBase64;
      const blob = new Blob(
        [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
        { type: "application/pdf" }
      );
      setPdfUrl(URL.createObjectURL(blob));
    } else {
      setTimeout(() => {
        fetchTakeback({ accessToken, id, setPdfUrl });
      }, 10000);
    }
  } catch (error) {
    console.error(error);
  }
};

export default function NextStepsPage() {
  const { t } = useTranslation();

  // Update right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("face");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  const matchingAddress = storeLocations.find(
    (location) => location.value === queryParams[TAKEBACK_METHOD_NAME]
  )?.address;
  const addressString = matchingAddress
    ? matchingAddress
    : t("trade-in.next_steps.address_not_found");

  const takeback = useTakebackById({ id: queryParams[TAKEBACK_ID] });

  const [pdfUrl, setPdfUrl] = useState();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.access_token;
  const once = useRef(false);
  const [printWhenReady, setPrintWhenReady] = useState(false);
  const [printWarningMessage, setPrintWarningMessage] = useState(
    <Trans i18nKey="trade-in.next_steps.print_warning" />
  );

  useEffect(() => {
    if (!once.current && takeback && !pdfUrl) {
      once.current = true;
      fetchTakeback({ accessToken, id: queryParams[TAKEBACK_ID], setPdfUrl });
    }
  }, [accessToken, pdfUrl, queryParams, takeback]);

  useEffect(() => {
    if (printWhenReady && pdfUrl) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = pdfUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        setTimeout(() => {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          setPrintWhenReady(false);
        }, 1);
      };
    }
  }, [pdfUrl, printWhenReady]);

  //funtciont to change the print warning message after 40 seconds to say that the label has failed
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPrintWarningMessage(
        <Trans
          i18nKey="trade-in.next_steps.print_failed"
          components={{
            1: (
              <a
                href="mailto:support@legobricktakeback.com"
                className="text-blue"
              >
                mailto:support@legobricktakeback.com
              </a>
            ),
          }}
        />
      );
    }, 180000);

    return () => clearTimeout(timeoutId);
  }, [t]);

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="w-auto lg:self-center xl:w-[660px] p-large md:p-xxx-large flex flex-col gap-x-large md:gap-xx-large">
        <div className="flex flex-col gap-regular">
          <h2 className="text-primary text-6xl">
            {t("trade-in.next_steps.title_thanks")}
          </h2>
          <p className="text-tertiary text-2xl">
            {t("trade-in.next_steps.confirmation_message")}
          </p>
        </div>
        <div className="flex flex-col gap-6 max-w-xl mt-4">
          <h3 className="text-2xl font-bold">
            {t("trade-in.next_steps.next_steps_title")}
          </h3>
          <div className="flex">
            <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
              1
            </div>
            <div>
              <Trans
                i18nKey={
                  queryParams[TAKEBACK_METHOD_NAME] === "mail"
                    ? "trade-in.next_steps.step_collect_mail"
                    : "trade-in.next_steps.step_collect_instore"
                }
                components={{
                  1: (
                    <Link
                      to={t("urls.preparation_checklist")}
                      className="cursor-pointer text-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </div>
          </div>
          {queryParams[TAKEBACK_METHOD_NAME] === "mail" ? (
            <Fragment>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  2
                </div>
                <div>
                  <span
                    className="text-blue cursor-pointer"
                    onClick={() => setPrintWhenReady(true)}
                  >
                    {t("trade-in.next_steps.step_mail_2.download")}
                  </span>{" "}
                  {t("trade-in.next_steps.step_mail_2.description", {
                    email: queryParams.p,
                  })}
                </div>
              </div>
              <InputValidation
                errorMessage={printWhenReady ? printWarningMessage : ``}
                fontSize="text-base"
                className="ml-8"
              />
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  3
                </div>
                <div className="flex flex-col gap-4">
                  <div>{t("trade-in.next_steps.step_mail_3")}</div>
                  <div>
                    <a
                      href={
                        queryParams[COUNTRY_NAME] === "DE"
                          ? "https://www.dhl.de/de/privatkunden/dhl-standorte-finden.html"
                          : "https://www.theupsstore.com/tools/find-a-store"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#016db8]"
                    >
                      {t("trade-in.next_steps.step_mail_find_ups")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  4
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <Trans
                      i18nKey="trade-in.next_steps.step_mail_4"
                      components={{
                        1: (
                          <Link
                            to={`${t("urls.my_trade_ins")}?locale=${queryParams.locale}&state=${queryParams.state}`}
                            className="text-blue"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  2
                </div>
                <div className="flex flex-col gap-4">
                  <div>{t("trade-in.next_steps.step_store_2")}</div>
                  <div>
                    {t("trade-in.next_steps.step_store_location", {
                      addressString,
                    })}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  3
                </div>
                <div>{t("trade-in.next_steps.step_store_3")}</div>
              </div>
            </Fragment>
          )}
        </div>
        <Button
          href={t("urls.my_trade_ins")}
          search={`?locale=${queryParams.locale}&state=${queryParams.state}`}
          className="w-fit"
        >
          {t("ui.button_go_to_trade_ins")}
        </Button>
      </div>
    </div>
  );
}
