import React, { useEffect } from "react";
import KioskStartButton from "../components/start-button";
import ArrowBack from "../assets/arrow-back.svg";
import { useTranslation } from "react-i18next";
import Cash from "assets/cashPict.jpg";
import GiftCard from "assets/giftcardPict.jpg";
import { calculateEstimate } from "lib/calculateEstimate";
import useLocalizedCurrencyValue from "hooks/useLocalizedCurrencyValue";
import { ENV_NEXT, ENV_PRODUCTION } from "hooks/useWhen";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

export default function Start({
  loggedWeights,
  navigate,
  setLoggedWeights,
  prevCustomerLoggedWeights,
  setprevCustomerLoggedWeights,
}) {
  //reset logged weights but keep a copy in case user navigates back
  useEffect(() => {
    if (loggedWeights.length > 0) {
      setLoggedWeights([]);
    }
  }, [loggedWeights, setLoggedWeights]);

  //get price per unit
  const pricePerUnit = calculateEstimate(1, "US");
  const pricePerUnitGift = useLocalizedCurrencyValue(pricePerUnit.gift);
  const pricePerUnitCash = useLocalizedCurrencyValue(pricePerUnit.payment);

  //translate
  const { t } = useTranslation();

  const wave3Changes = useWhen({ env: [ENV_NEXT, ENV_PRODUCTION] });
  const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <div className="flex flex-col items-center w-full h-full px-[4vh]">
      <div className="fixed flex top-[3vh] px-[3vh] justify-between w-full">
        {prevCustomerLoggedWeights.length !== 0 ? (
          <KioskStartButton
            iconShow={false}
            className="bg-[#0000000c] px-10 background "
            onClick={() => {
              setLoggedWeights(prevCustomerLoggedWeights);
              setprevCustomerLoggedWeights([]);
              navigate("finish");
            }}
          >
            <img src={ArrowBack} alt="Arrow icon" className="w-[2vh]" />
          </KioskStartButton>
        ) : (
          <div />
        )}
        <KioskStartButton
          className="font-semibold w-1/4"
          iconShow={false}
          onClick={() => navigate("terms")}
        >
          Start
        </KioskStartButton>
      </div>
      {/* <div className="mr-auto mb-auto">
      <button
        onClick={() => navigate("start")}
        type="button"
        class="bg-gray-300 rounded-full p-2 inline-flex items-center justify-center text-[#000000] focus:outline-none focus:ring-2 focus:ring-inset"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div> */}
      <h1 className="text-[80px] mt-[2vh]  text-white">
        LEGO® Brick Take Back
      </h1>
      <div className="flex flex-col gap-[0vh] items-center mt-[3vh] text-white">
        <p className="text-[112px] leading-[130%] text-center max-w-[45vh] mb-[5vh]  text-white">
          Trade in your <br />
          LEGO bricks for a LEGO e-Gift Card{showPayment && " or digital cash"}!
        </p>
        <p className="text-[60px] max-w-[43vh] w-full text-center text-yellow font-bold mt-[2vh]">
          Chat with one of our
          <br />
          Brick Specialists to get started.
        </p>
        {wave3Changes ? (
          <p className="text-[50px] max-w-[43vh] w-full text-center font-light mt-[1vh]">
            Then weigh your bricks and score a LEGO e-Gift Card. Simple as that.
          </p>
        ) : (
          <p className="text-[50px] max-w-[43vh] w-full text-center font-light mt-[1vh]">
            Then weigh your bricks and select your reward. Simple as that.
          </p>
        )}

        <div className="flex gap-[3vh] justify-center mt-[3vh]">
          <div className="flex items-center flex-col text-center flex-nowrap gap-[1vh]">
            <img
              src={GiftCard}
              alt="Cash Icon"
              className="w-[8vh] aspect-[16/10] rounded-[.8vh]"
            />
            <div className="flex flex-col gap-x-small text-[45px]">
              <div className="font-bold">
                {t("ui.reward_overview.giftcard_title")}
              </div>
              <div className="text-[38px] text-white font-light">
                {pricePerUnitGift} per pound of eligible bricks
              </div>
            </div>
          </div>
          {!wave3Changes && (
            <>
              <div className="flex items-center gap-[1vh]">
                <div className="w-[1vh] h-[.1vh] bg-[#fff]"></div>
                <div className="text-[45px] font-bold">
                  {t("ui.reward_overview.or")}
                </div>
                <div className="w-[1vh] h-[.1vh] bg-[#fff]"></div>
              </div>
              <div className="flex items-center flex-col text-center flex-nowrap gap-[1vh]">
                <img
                  src={Cash}
                  alt="Cash Icon"
                  className="w-[8vh] aspect-[16/10] rounded-[.8vh]"
                />
                <div className="flex flex-col gap-x-small text-[45px]">
                  <div className="font-bold">
                    {t("ui.reward_overview.cash_title")}
                  </div>
                  <div className="text-[38px] text-white font-light">
                    {/* {t("ui.reward_overview.cash_value_landing", {
                value: pricePerUnitCash,
              })} */}
                    {pricePerUnitCash} per pound of eligible bricks
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
