import React, { useContext, useReducer } from "react";
import packagesSvg from "./svgs/packages.svg";

import { WarehouseProvider } from "./WarehouseProvider";
import { stepReducer } from "./stepReducer";
import AuthContext from "../../../contexts/AuthContext";
import { Snackbar, Fade } from "@mui/material";

export const WarehousePackages = () => {
  const [state, dispatch] = useReducer(stepReducer, { currentStep: -1 });
  const { user } = useContext(AuthContext);

  const { StepComponent, props = {} } = state;
  const { successMessage } = props;

  return (
    <WarehouseProvider>
      {StepComponent ? (
        <StepComponent dispatch={dispatch} />
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-[#EFF6FF]">
          <div className="absolute top-0 flex p-4 bg-white w-full h-[6rem] justify-end items-center">
            <div className="flex flex-row justify-end">
              <div className="text-2xl">{user.email}</div>
            </div>
          </div>

          <div className="text-center font-sans p-4">
            <div className="flex flex-col items-center justify-center gap-4">
              <img src={packagesSvg} style={{ width: "6rem" }} alt="Packages" />
              <span>
                <h1 className="font-semibold text-6xl max-w-lg">Packages</h1>
                <p className="text-tertiary text-2xl">
                  Check-in or process packages
                </p>
              </span>

              <button
                className="rounded-lg text-4xl bg-blue text-white px-x-large py-regular mt-large"
                onClick={() => {
                  dispatch({ type: "next" });
                }}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        TransitionComponent={Fade}
        open={Boolean(successMessage)}
        onClose={() => {
          dispatch({ type: "clear" });
        }}
        autoHideDuration={5000}
      >
        <div className="border-l-4 border-l-[#00CFA8] bg-[#D5F8F1] p-4">
          {successMessage}
        </div>
      </Snackbar>
    </WarehouseProvider>
  );
};
