import React, { useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { Input } from "components/input";
import { getQueryParams } from "lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "lib/utils";
import { isLegoEmail } from "consts/lego-admin";
import { useUserExists } from "api/use-user-exists";
import InputValidation from "components/input-validation";

export default function StoreEmail() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailAlarm, setEmailAlarm] = useState(true);
  const [emailTouched, setEmailTouched] = useState(false);
  const [legoError, setLegoError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search);
  const { exists, isLoading } = useUserExists({
    email,
    skip: !isValidEmail(email),
  });

  const destination = exists
    ? t("urls.signup_store_sign_in_exists")
    : t("urls.signup_store_sign_up");

  // Handle input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailAlarm(!isValidEmail(value));
    if (isLegoEmail(value)) {
      setLegoError(t("login.lego_alarm"));
    } else {
      setLegoError(null);
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    setEmailAlarm(!isValidEmail(email));
  };

  const handleContinue = () => {
    localStorage.setItem("emailInput", email);
    navigate(destination);
  };

  const updatedParams = { ...queryParams };

  return (
    <FormContainer
      submitLabel="Continue"
      submitHref={destination}
      submitParams={updatedParams}
      secondaryParams={updatedParams}
      onSubmit={handleContinue}
      submitDisabled={!email || emailAlarm || isLoading || legoError}
      secondaryLabel="Back"
      secondaryHref={`${t("urls.signup_store_checklist")}`}
    >
      <ProgressBar step={2} stepsNum={3} />
      <div className="flex flex-col gap-regular">
        <h2 className="font-semibold text-6xl max-w-lg">
          Sign in or sign up to continue
        </h2>
      </div>
      <div className="w-full mb-6">
        <Input
          name="email"
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          value={email}
          label="Email Address"
          type="email"
        />
        {legoError && (
          <InputValidation className="mt-2" errorMessage={legoError} />
        )}
        {emailAlarm && emailTouched && (
          <div className="text-secondary text-sm mt-regular">
            <span className="material-symbols-outlined scale-75 text-red align-bottom mr-1">
              error
            </span>
            Please enter a valid email.
          </div>
        )}
        <div className="mt-8 text-left flex gap-1 items-start">
          <span
            className={`material-symbols-outlined scale-75 text-blue align-bottom block`}
          >
            info
          </span>{" "}
          <span className="text-sm text-gray-500">
            {t("login.sign_up_warning")}
          </span>
        </div>
      </div>
    </FormContainer>
  );
}
