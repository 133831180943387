import { when } from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

export const calculateEstimate = (
  weight,
  country = "US",
  existingGiftRatio
) => {
  const showPayment = when(PAYMENT_ARGS);
  const giftRatio =
    existingGiftRatio ||
    (country === `US` ? (showPayment ? 4 : 6) : showPayment ? 8 : 12);
  let payment, gift;

  if (country === "US") {
    payment = (weight * 2.4).toFixed(2);
    gift = (weight * giftRatio).toFixed(2);
  } else {
    payment = (weight * 4.8).toFixed(2);
    gift = (weight * giftRatio).toFixed(2);
  }
  return { gift, payment };
};
