import React, { useState } from "react";

import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import PinModal from "./components/PinModal";
import { AnimatePresence } from "framer-motion";

import { useStepsWithData } from "./useStepsWithData";

import { ReactComponent as WarningSvg } from "./svgs/warning.svg";
import { ReactComponent as ArrowSvg } from "./svgs/arrow.svg";
import { ReactComponent as InfoSvg } from "./svgs/info.svg";
import { useWarehouseCheckPin } from "../../../api/use-warehouse-check-pin";
import { StepHeader } from "./components/StepHeader";
import { BusyFullscreen } from "./components/BusyFullscreen";
import { FullscreenModal } from "./components/FullscreenModal";
import { usePluralizer } from "./utils/usePluralizer";
import { useLocale } from "./useLocale";

export const ProcessingIssues = ({ dispatch }) => {
  const { updateTakeback, clearTakeback } = useWarehouseContext();
  const { mutateAsync: checkPin } = useWarehouseCheckPin();

  const stepsData = useStepsWithData();

  const [showPinModal, setShowPinModal] = useState(false);

  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const locale = useLocale();
  const pluralizer = usePluralizer(locale);

  const onFinish = async () => {
    try {
      setIsUpdating(true);
      await checkPin(pin);
      setShowPinModal(false);

      const { result } = await updateTakeback();

      if (result?.u_lego_escalated === "true") {
        dispatch({ type: "show-escalated" });
      } else {
        clearTakeback();

        dispatch({
          type: "exit",
          props: { successMessage: "Successfully processed package" },
        });
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const jumpTo = (StepComponent) => {
    dispatch({
      type: "jump-to",
      props: { StepComponent },
    });
  };

  const handleModalClose = () => {
    setShowPinModal(false);
    setPin("");
    setError("");
  };

  const incompleteSteps = stepsData.filter((step) => !step.completed);
  const errors = stepsData.filter((step) => step.error);

  return (
    <>
      <FullscreenModal>
        <StepHeader dispatch={dispatch} showModal={true} />
        {showPinModal && (
          <AnimatePresence>
            <PinModal
              isOpen={showPinModal}
              onClose={() => handleModalClose()}
              onConfirm={onFinish}
              title={`Override Incomplete Processing`}
              body="Enter a pin to finish with incomplete steps."
              primaryText="Confirm"
              secondaryText="Cancel"
              pin={pin}
              setPin={setPin}
              error={error}
              isUpdating={isUpdating}
            />
          </AnimatePresence>
        )}
        <div className="relative w-full py-4 pb-[8rem] min-h-[calc(100vh-6rem)] flex justify-center">
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-center items-center ">
              <h1 className="font-semibold text-3xl max-w-lg">
                Processing Issues
              </h1>
              <p className="text-xl font-medium text-[#464C64]">
                Please review all issues before proceeding, or enter a pin to
                finish anyway.
              </p>

              {incompleteSteps.length > 0 && (
                <>
                  <h2 className="text-[18px] font-bold mr-auto text-[#282F44] text-left pb-4 pt-6">
                    {pluralizer(incompleteSteps.length, "Incomplete Step")}
                  </h2>
                  <ul className="w-full flex flex-col gap-4 mt-x-large">
                    {incompleteSteps.map(
                      ({ StepComponent, details }, index) => {
                        return (
                          <li
                            key={`incomplete-step-${index}`}
                            className="flex flex-row items-center justify-center text-xl pb-4 border-b border-b-[#DAE3F9]"
                          >
                            <WarningSvg style={{ width: "4rem" }} />
                            <span className="flex flex-col gap-1 justify-center w-full ml-6">
                              <b className="text-[#282F44] text-[16px]">
                                {StepComponent.title}
                              </b>
                              <p className="text-sm text-[#464C64] text-[14px]">
                                {details}
                              </p>
                            </span>
                            <button
                              className="border-none flex flex-row gap-2 items-center w-full justify-end text-[#2256DB] text-xl"
                              onClick={() => {
                                jumpTo(StepComponent);
                              }}
                            >
                              Complete Step <ArrowSvg />
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}

              {errors.length > 0 && (
                <>
                  <h2 className="text-[18px] font-bold mr-auto text-[#282F44] text-left pb-4 pt-6">
                    {pluralizer(errors.length, "Error")}
                  </h2>
                  <ul className="w-full flex flex-col gap-4 mt-x-large">
                    {errors.map(
                      ({ StepComponent, title, details, linkText }) => {
                        return (
                          <li
                            key={title}
                            className="flex flex-row items-center pb-4 border-b border-b-[#DAE3F9]"
                          >
                            <InfoSvg
                              style={{ width: "3rem", fill: "#C12960" }}
                            />
                            <span className="flex flex-col gap-1 w-full ml-6">
                              <b className="text-[#282F44] text-[16px]">
                                {title}
                              </b>
                              <p className="text-sm text-[#464C64] text-[14px]">
                                {details}
                              </p>
                            </span>
                            <button
                              className="border-none flex flex-row gap-2 items-center justify-end text-[#2256DB] text-xl"
                              onClick={() => {
                                jumpTo(StepComponent);
                              }}
                            >
                              {linkText} <ArrowSvg />
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
          <Footer opacity="100%">
            <div className="flex gap-4 flex-col p-8">
              <div className="grid grid-cols-2 w-full gap-8">
                <button
                  className="rounded-lg font-normal border-2 border-[#DAE3F9] py-4 w-full m-0"
                  onClick={() => {
                    dispatch({ type: "previous" });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-[#173991] text-white font-normal py-4 w-full m-0 disabled:bg-[#C6CCE8] disabled:cursor-not-allowed"
                  onClick={() => {
                    setShowPinModal(true);
                  }}
                >
                  Finish
                </button>
              </div>
            </div>
          </Footer>
        </div>
      </FullscreenModal>
      {isUpdating && !showPinModal && (
        <BusyFullscreen message="Finalising Take Back" />
      )}
    </>
  );
};
