import Webcam from "react-webcam";
import React, { useRef, useState } from "react";
import { useWarehouseContext } from "./useWarehouseContext";
import { ReactComponent as PhotoSvg } from "./svgs/photo.svg";
import { ReactComponent as DeleteSvg } from "./svgs/delete.svg";
import { ReactComponent as SpinnerSvg } from "./svgs/tail-spin.svg";

import { useWindowSize } from "./useWindowSize";
import { Footer } from "./components/Footer";
import { TakePhotoButton } from "./components/TakePhotoButton";
import { StepHeader } from "./components/StepHeader";
import { GotoAllStepsButton } from "./components/GotoAllStepsButton";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Package Photos";

export const CapturePackagePhotos = ({ dispatch }) => {
  const { width, height, aspectRatio } = useWindowSize();
  const [showThumbnails, setShowThumbnails] = useState(false);
  const { packagePictures, addPackagePicture, deletePackagePicture } =
    useWarehouseContext();
  const webcamRef = useRef(null);

  const handleCapturePackage = (picture) => {
    addPackagePicture(picture);
  };

  return (
    <FullscreenModal>
      <div className="absolute inset-0 flex flex-col items-center justify-center bg-white">
        <SpinnerSvg className="w-8 h-8" />
        <p className="mt-4 text-[21px] font-semibold text-[#282F44]">
          Initializing Camera
        </p>
      </div>
      <div className="fixed">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={`${width}`}
          height={`${height}`}
          screenshotQuality={1}
          forceScreenshotSourceSize={true}
          videoConstraints={{
            width: { ideal: width },
            height: { ideal: height },
            aspectRatio: { ideal: aspectRatio },
            facingMode: { ideal: "environment" },
          }}
        />
      </div>
      <StepHeader dispatch={dispatch} opacity="100%" />

      <Footer>
        <div className="flex gap-8 flex-col p-8 pt-4">
          <div className="w-full flex flex-col">
            <div className="flex flex-row justify-between">
              <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                {`${TITLE} `}
                <span className="relative">
                  <PhotoSvg style={{ width: "34px" }} />
                  {packagePictures?.length > 0 && (
                    <sup className="absolute top-0 right-[-0.25rem] rounded-full w-5 h-5 bg-red text-white text-sm text-center">
                      {packagePictures.length}
                    </sup>
                  )}
                </span>
              </h1>
              <div className="flex flex-row gap-4">
                <button
                  disabled={packagePictures.length === 0}
                  className="p-6 bg-white rounded-lg border-2 border-[#DAE3F9]"
                  onClick={() => {
                    setShowThumbnails(!showThumbnails);
                  }}
                >
                  <PhotoSvg style={{ width: "24px", height: "24px" }} />
                </button>
                <GotoAllStepsButton dispatch={dispatch} />
              </div>
            </div>
            <p className="text-4xl font-medium text-[#464C64]">
              Take a photo of the package with its label visible. Take
              additional photos of any package damages.
            </p>
          </div>

          {showThumbnails && (
            <div className="text-white h-full flex flex-row gap-2 align-top">
              <div className="grid gap-4 grid-cols-6 lg:grid-cols-12">
                {packagePictures.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`Captured ${index}`}
                      className="w-full h-auto border rounded-lg"
                    />
                    <button
                      onClick={() => deletePackagePicture(image)}
                      className="absolute top-[-1rem] right-[-1rem]"
                    >
                      <DeleteSvg
                        className="w-6 h-6 fill-red"
                        alt="Delete image"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-row w-full gap-8">
            <TakePhotoButton
              dispatch={dispatch}
              onPhotoCapture={handleCapturePackage}
              webcam={webcamRef.current}
              title={TITLE}
            />
            <button
              className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
              disabled={false}
              onClick={() => {
                dispatch({ type: "next" });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};

CapturePackagePhotos.title = TITLE;
