import { useEffect, useState } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import MyTradeInsCard from "components/my-trade-ins-card";
import { useMyTakebacks, useCreateTakeback } from "api/use-takebacks";
import PayallModal from "components/payall-modal";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import ImpressumFooter from "components/footer-impressum";
import LoadingSpinner from "components/loading-spinner";
import { getQueryParams } from "lib/utils";

export const MyTradeIns = () => {
  const { data: takebacks, refetch, isLoading } = useMyTakebacks();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const locationId = localStorage.getItem("locationId");
  const storeLocations = {
    80401: { state: "CO", country: "US" },
    30043: { state: "GA", country: "US" },
  };
  const storeLocaction = storeLocations[locationId];

  // State to update the payment status after Payall modal is completed without needing a page reload / re-fetch
  const [payallCompleted, setPayallCompleted] = useState(false);

  useCreateTakeback();

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        refetch();
      },
      takebacks?.length > 5 ? 10000 : 5000
    );

    return () => clearInterval(intervalId);
  }, [refetch, takebacks]);

  return (
    <div className="min-h-screen relative flex flex-col justify-between">
      <div>
        <Header />
        {isLoading ? (
          <LoadingSpinner isLoading />
        ) : (
          <div className="container min-h-[calc(100vh - 81px)] m-auto py-16 px-4 md:px-0">
            <h1 className="text-6xl font-semibold">
              {t("my_trade_ins.title")}
            </h1>
            {takebacks?.map((takeback) => (
              <>
                <AnimatePresence>
                  {modalOpen === takeback.number && (
                    <PayallModal
                      caseId={takeback.number}
                      setModalOpen={setModalOpen}
                      setPayallCompleted={(payallComplete) => {
                        refetch();
                        setPayallCompleted(payallComplete);
                      }}
                    />
                  )}
                </AnimatePresence>
                {takeback.u_lego_return_type !== "credit" && (
                  <MyTradeInsCard
                    key={takeback.number}
                    weight={
                      takeback.u_lego_verified_at ||
                      takeback.u_lego_in_store_verified_at
                        ? parseFloat(takeback.u_lego_payment_weight).toFixed(1)
                        : parseFloat(takeback.u_lego_presort_weight).toFixed(1)
                    }
                    u_tango_redeem_link={takeback.u_tango_redeem_link}
                    returnMethod={takeback.u_lego_return_type}
                    createdAt={takeback.sys_created_on}
                    receivedAt={takeback.u_lego_received_at}
                    verifiedAt={
                      takeback.u_lego_verified_at ||
                      takeback.u_lego_in_store_verified_at
                    }
                    takebackIban={takeback.u_lego_seller_iban}
                    issuedAt={takeback.u_lego_payment_issued_at}
                    payoutMethod={takeback.u_lego_payment_type}
                    unit={takeback?.consumer?.country === "DE" ? "kg" : "lbs"}
                    caseId={takeback?.number}
                    takeBackCountry={
                      takeback?.consumer?.country === "DE" ? "de-DE" : "en-US"
                    }
                    currencySymbol={
                      takeback?.consumer?.country === "DE" ? "€" : "$"
                    }
                    shipping={
                      takeback.u_lego_dhl_label_b64_encoded ||
                      takeback.u_ups_shipping_label_encoded
                    }
                    receipt={takeback.u_seller_receipt_encoded}
                    sys_id={takeback.sys_id}
                    setPayallOpen={() => setModalOpen(takeback.number)}
                    payallCompleted={payallCompleted}
                    incentiveGiftRatio={takeback.u_lego_incentive_ratio}
                    caseNumber={takeback.number}
                  />
                )}
              </>
            ))}
            <Button
              href={t("urls.trade_in_weight")}
              variant="secondary"
              className="mt-8"
              queryParams={{
                country:
                  queryParams.country || storeLocaction?.country || `null`,
                state: queryParams.state || storeLocaction?.state || `null`,
              }}
            >
              {t("ui.button_start")}
            </Button>
          </div>
        )}
      </div>
      <ImpressumFooter className="" />
    </div>
  );
};
